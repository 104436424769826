/**
 * Feed
 */

import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Section from '../components/section';
import FeedCard from '../components/feed-card';

export default function Feed({ data }) {
  const pageInfo = {
    id: 'feed-page',
    url: '/news',
    title: 'Новости',
    description: 'Новости КМК клиники.',
    breadcrumbs: [
      {
        title: 'Главная',
        link: '/',
      },
      {
        title: 'Новости',
        link: null,
      },
    ],
  };

  const pagesByRows = [[]];

  for (let i = 0, j = 0; i < data.allStrapiFeed.edges.length; i += 1) {
    if (!(j < 4)) {
      pagesByRows.push([]);
      j = 0;
    }

    pagesByRows[pagesByRows.length - 1].push(data.allStrapiFeed.edges[i]);
    j += 1;
  }

  return (
    <Layout pageInfo={pageInfo}>
      <Section>
        {
          pagesByRows.map((row) => (
            <div className="row clearfix">
              {
                row.map((page) => (
                  <div className="column column--1-of-4">
                    <FeedCard feed={{ ...page.node }} link="news" />
                  </div>
                ))
              }
            </div>
          ))
        }
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query {
    allStrapiFeed(sort: { fields: date, order: DESC }) {
      edges {
        node {
          strapiId
          slug
          title
          date(formatString: "DD.MM.YYYY")
          cover {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
