import React from 'react';
import { FiCalendar } from 'react-icons/fi';
import { GatsbyImage } from 'gatsby-plugin-image';

import './feed-card.css';

// News and offers
export default function FeedCard({ feed, link }) {
  return (
    <div className="feed-card">
      <a href={`/${link}/${feed.slug}`}>
        <div className="feed-card__cover">
          <GatsbyImage
            image={feed.cover.childImageSharp.gatsbyImageData}
            alt={feed.title}
          />
        </div>
        <div className="feed-card__title">
          <h3>
            {feed.title}
          </h3>
          <div className="feed-card__date">
            <span>
              <FiCalendar
                style={{
                  verticalAlign: 'middle',
                  marginTop: '-4px',
                  width: '16px',
                  height: '16px',
                }}
                color="#2055a4"
              />
              &nbsp;
            </span>
            <span>
              {feed.date}
            </span>
          </div>
        </div>
      </a>
    </div>
  );
}
